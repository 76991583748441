import React from "react"
import ImageGallery from "react-image-gallery"

export const RenderAuthor = ({ authorObject }) => {
  // i.e. if authorObject is not a null value
  if (authorObject) {
    return (
      <p>
        <b>Author:</b> {authorObject.author}
      </p>
    )
  } else {
    return null
  }
}

export const RenderAttachments = ({ attachmentObect }) => {
  if (attachmentObect) {
    return (
      <div>
        <ol>
          {attachmentObect.map((attachment, index) => (
            <li key={index} className="list-disc">
              <a
                href={attachmentObect[index].file.url}
                download
                className="underline"
              >
                {attachmentObect[index].title}
              </a>
            </li>
          ))}
        </ol>
      </div>
    )
  } else {
    return null
  }
}

export const renderGallery = imageObject => {
  if (imageObject) {
    let IMAGES = []

    imageObject.map((image, index) => {
      let singleImage = {
        original: image.fluid.src,
        thumbnail: image.fixed.src,
        originalAlt: image.title || "hockey photo",
        thumbnailAlt: `${image.title} thumbnail` || "hockey photo thumbnail",
      }
      return IMAGES.push(singleImage)
    })

    return (
      <ImageGallery
        items={IMAGES}
        showPlayButton={false}
        showFullscreenButton={false}
        infinite={false}
      />
    )
  }
}

export const SeparatorLine = () => <div className="base__separatorLine" />

// inputs: array from media query
//purpose: removes all of the entries with fields marking them as placeholder content
// rationale: graphql does not play well if queries don't yield anything.  Each content type has placeholder content in order to avoid build errors from this
// placeholder content is filtered out

export const removePlaceholder = dataArray => {
  const newDataArray = dataArray.filter(item => {
    return !item.node.placeholder === true
  })
  return newDataArray
}
